import {
  FORM_CONTENT_MENTEE,
  FORM_CONTENT_MENTOR,
  FormType,
} from "../../pages/form/answers.content";
import { useEffect, useState } from "react";

import { UserProfile } from "../../pages/authentication/Login";
import { create } from "zustand";

export interface MatchaStore {
  userProfile: UserProfile | null;
  setUserProfile: (profile: UserProfile) => void;
  removeUserProfile: () => void;
}

export const useMatchaStore = create<MatchaStore>((set) => ({
  userProfile: null,
  setUserProfile: (profile: UserProfile) =>
    set(() => ({ userProfile: profile })),
  removeUserProfile: () => set({ userProfile: null }),
}));

export function useFormSubmissions(
  userId: string | undefined,
  formType: FormType
) {
  const [formSubmissions, setFormSubmissions] = useState<
    Map<string, string | Array<string>>
  >(new Map());
  const fieldIdToName: Map<string, string> = mapFieldIdsToNames();

  function mapFieldIdsToNames() {
    const map = new Map<string, string>();
    if (formType === FormType.MENTOR) {
      for (const field of FORM_CONTENT_MENTOR) {
        map.set(field.fieldId, field.name);
      }
    }
    if (formType === FormType.MENTEE) {
      for (const field of FORM_CONTENT_MENTEE) {
        map.set(field.fieldId, field.name);
      }
    }
    return map;
  }

  function parseCurlyArray(value: string): Array<string> {
    return value.replace("{", "").replace("}", "").split(",");
  }

  async function fetchFormSubmissions() {
    const response = await fetch(`/u/${userId}/form-submissions`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (response.ok) {
      const payload = await response.json();
      const mapForForm = new Map<string, string | Array<string>>();
      payload.forEach((field: any) => {
        if (field.value.startsWith("{")) {
          field.value = parseCurlyArray(field.value);
        }
        const fieldName = fieldIdToName.get(field.field_id);
        if (field.form_id === formType && fieldName !== undefined) {
          mapForForm.set(fieldName, field.value);
        }
      });
      setFormSubmissions(mapForForm);
    } else {
      console.error("Network response was not ok.");
    }
  }

  useEffect(() => {
    if (userId) {
      fetchFormSubmissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return formSubmissions;
}

export function useMatches(
  userId: string | undefined,
  userType: "mentor" | "mentee"
) {
  const [matches, setMatches] = useState<Array<Record<string, string>>>([]);
  async function fetchMatches() {
    const response = await fetch(`/u/${userId}/${userType}-matches`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (response.ok) {
      const payload = await response.json();
      setMatches(payload);
    } else {
      console.error("Network response was not ok.");
    }
  }

  useEffect(() => {
    if (userId) {
      fetchMatches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return matches;
}
