import { Box, Button, Heading, Text } from "@flixbus/honeycomb-react";
import { useCallback, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { Navigate } from "react-router-dom";
import styles from "./Authentication.module.scss";

export interface UserProfile {
  displayName: string;
  mail: string;
  preferredLanguage: string;
  jobTitle: string;
  id: string;
  officeLocation: string;
}

interface LoginProps {
  setUserProfile: (profile: UserProfile) => void;
  userProfile: UserProfile | null;
}

export function Login(props: LoginProps) {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  function login() {
    instance
      .loginRedirect({
        scopes: ["User.Read"],
      })
      .catch(console.error);
  }

  async function callMsGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch("https://graph.microsoft.com/v1.0/me", options)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }

  async function requestProfileFunc() {
    try {
      await instance.initialize();
      const authResult = await instance.acquireTokenSilent({
        account: accounts[0],
        scopes: ["User.Read"],
      });
      const graphData = await callMsGraph(authResult.accessToken);
      props.setUserProfile(graphData);
    } catch (error) {
      console.error(error);
    }
  }
  const requestProfile = useCallback(requestProfileFunc, [
    accounts,
    instance,
    props,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      requestProfile();
    }
  }, [isAuthenticated, requestProfile]);

  if (props.userProfile) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box extraClasses={styles.AuthBox}>
      <Heading size={3} sectionHeader>
        Welcome to Matcha
      </Heading>
      <Text>
        You need to login in order to use this application. Please click the
        button below to open the authentication dialog.
      </Text>
      <Button appearance="tertiary" onClick={login}>
        Login
      </Button>
    </Box>
  );
}
