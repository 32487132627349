import {
  Header as HCHeader,
  HeaderBrand,
  HeaderNavigation,
  HeaderSubNavigation,
  HeaderUserWidget,
  HeaderWidgets,
  NavItem,
} from "@flixbus/honeycomb-react";
import { NavLink, useMatch } from "react-router-dom";

import { APP_PATHS } from "../../constants";
import React from "react";
import { useMatchaStore } from "../state-management/matcha-state";

const Header = () => {
  const userProfile = useMatchaStore((state) => state.userProfile);

  return (
    <HCHeader fixed={false}>
      <HeaderBrand
        alt="FlixBus"
        href={APP_PATHS.HOME}
        RouterLink={NavLink}
        src="https://honeycomb.flixbus.com/dist/7.0.0/img/logos/svg/flix-white.svg"
        width="126"
        height="24"
      />
      <HeaderNavigation aria-label="Main">
        <NavItem
          {...(useMatch("/forms/*") ? { "aria-current": "page" } : {})}
          subMenu={
            <HeaderSubNavigation id="profile-sub-nav">
              <NavItem RouterLink={NavLink} to={APP_PATHS.MENTEE_FORM}>
                Mentee
              </NavItem>
              <NavItem RouterLink={NavLink} to={APP_PATHS.MENTOR_FORM}>
                Mentor
              </NavItem>
            </HeaderSubNavigation>
          }
        >
          My Profile
        </NavItem>
        <NavItem
          {...(useMatch("/matches/*") ? { "aria-current": "page" } : {})}
          subMenu={
            <HeaderSubNavigation id="matches-sub-nav">
              <NavItem RouterLink={NavLink} to={APP_PATHS.MENTEE_MATCHES}>
                Mentee
              </NavItem>
              <NavItem RouterLink={NavLink} to={APP_PATHS.MENTOR_MATCHES}>
                Mentor
              </NavItem>
            </HeaderSubNavigation>
          }
        >
          My Matches
        </NavItem>
      </HeaderNavigation>
      <HeaderWidgets>
        {userProfile ? (
          <HeaderUserWidget
            subMenu={
              <HeaderSubNavigation alignment="left" id="user-sub-menu">
                <NavItem href="/logout">Logout</NavItem>
              </HeaderSubNavigation>
            }
          >
            {userProfile.displayName}
          </HeaderUserWidget>
        ) : (
          <NavLink to={APP_PATHS.LOGIN}>Login</NavLink>
        )}
      </HeaderWidgets>
    </HCHeader>
  );
};

export default Header;
