import * as apiSchema from "../../../../schemas/mentorship.json";

import {
  Box,
  Button,
  Checkbox,
  Fieldset,
  FormRow,
  Legend,
  MainWrapper,
  PageContainer,
  Select,
  SelectOption,
  Text,
} from "@flixbus/honeycomb-react";
import { FORM_CONTENT_MENTOR, FormType } from "../answers.content";
import React, { useEffect } from "react";
import {
  useFormSubmissions,
  useMatchaStore,
} from "../../../features/state-management/matcha-state";

import { APP_PATHS } from "../../../constants";
import { buildYup } from "schema-to-yup";
import styles from "./MentorForm.module.scss";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const SelectOptionFix = SelectOption as any;

const MentorForm = () => {
  const navigate = useNavigate();
  const userProfile = useMatchaStore((state) => state.userProfile);
  const existingSubmissions = useFormSubmissions(
    userProfile?.id,
    FormType.MENTOR
  );

  const mentorshipDefault = existingSubmissions.get("mentorship_approach");
  const functionalDefault = existingSubmissions.get("functional_expertise");
  const seniorityDefault = existingSubmissions.get("seniority_level");
  const workExpDefault = existingSubmissions.get("work_exp_at_flix");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mentorship_approach: mentorshipDefault || ([] as Array<string>),
      functional_expertise: functionalDefault || ([] as Array<string>),
      seniority_level: seniorityDefault || "0",
      work_exp_at_flix: workExpDefault || "0",
    },
    onSubmit: submitForm,
    validationSchema: buildYup(apiSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function submitForm(formValues: any) {
    if (!userProfile) {
      console.error("userProfile is null");
      return;
    }
    formValues.work_exp_at_flix = parseInt(formValues.work_exp_at_flix);
    formValues.seniority_level = parseInt(formValues.seniority_level);
    try {
      const fields = Object.keys(formValues).map((key) => {
        // TODO: This is a hack to use the fieldId. In the future this should be
        // simplified by using the field name or improved to be fully backend driven.
        const field = FORM_CONTENT_MENTOR.find((field) => field.name === key);
        return { field_id: field?.fieldId, field_value: formValues[key] };
      });
      const forms = [{ form_id: FormType.MENTOR, fields }];
      const body = {
        display_name: userProfile.displayName,
        full_name: userProfile.displayName,
        email: userProfile.mail,
        job_title: userProfile.jobTitle,
        team: "Team Matcha", // TODO: fetch from workday
        office_location: userProfile.officeLocation,
        forms,
      };
      const userUuid = userProfile.id;
      const response = await fetch(`/u/${userUuid}/mentor-form/save`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      navigate(APP_PATHS.MENTOR_MATCHES);
      if (response.ok) {
        return;
      }
      throw new Error("Network response was not ok.");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <MainWrapper full extraClasses={styles.backgroudColor}>
      <PageContainer extraClasses={styles.pageContainer}>
        <form onSubmit={formik.handleSubmit}>
          <FormRow>
            <Box>
              <Text Elem="div">
                <h2>Mentor registration</h2>
                <p>
                  Tell us more about areas where you can offer mentorship, so
                  you can 🍵 with the right mentee.
                </p>
                <p>(*) required</p>
              </Text>
            </Box>
          </FormRow>
          <FormRow>
            <Box>
              <Fieldset
                info={FORM_CONTENT_MENTOR[0].info}
                itemSpacing="half"
                infoError={
                  formik.touched?.mentorship_approach &&
                  formik.errors.mentorship_approach
                }
                valid={
                  formik.touched.mentorship_approach &&
                  formik.errors.mentorship_approach
                    ? false
                    : true
                }
              >
                <Legend>{FORM_CONTENT_MENTOR[0].question}</Legend>
                {FORM_CONTENT_MENTOR[0].answers.map((answer) => (
                  <Checkbox
                    small
                    name={FORM_CONTENT_MENTOR[0].name}
                    key={`${FORM_CONTENT_MENTOR[0].name}-${answer.value}`}
                    id={`${FORM_CONTENT_MENTOR[0].name}-${answer.value}`}
                    label={answer.label}
                    value={answer.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.mentorship_approach.includes(
                      answer.value
                    )}
                  />
                ))}
              </Fieldset>
            </Box>
          </FormRow>
          <FormRow>
            <Box>
              <Fieldset
                info={FORM_CONTENT_MENTOR[1].info}
                itemSpacing="half"
                infoError={
                  formik.touched.functional_expertise &&
                  formik.errors.functional_expertise
                }
                valid={
                  formik.touched.functional_expertise &&
                  formik.errors.functional_expertise
                    ? false
                    : true
                }
              >
                <Legend>{FORM_CONTENT_MENTOR[1].question}</Legend>
                {FORM_CONTENT_MENTOR[1].answers.map((answer) => (
                  <Checkbox
                    small
                    name={FORM_CONTENT_MENTOR[1].name}
                    key={`${FORM_CONTENT_MENTOR[1].name}-${answer.value}`}
                    id={`${FORM_CONTENT_MENTOR[1].name}-${answer.value}`}
                    label={answer.label}
                    value={answer.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.functional_expertise.includes(
                      answer.value
                    )}
                  />
                ))}
              </Fieldset>
            </Box>
          </FormRow>
          <FormRow>
            <Box>
              <Fieldset
                info={FORM_CONTENT_MENTOR[3].info}
                itemSpacing="half"
                infoError={
                  formik.touched.seniority_level &&
                  formik.errors.seniority_level
                }
                valid={
                  formik.touched.seniority_level &&
                  formik.errors.seniority_level
                    ? false
                    : true
                }
              >
                <Select
                  id={`${FORM_CONTENT_MENTOR[3].name}`}
                  label={FORM_CONTENT_MENTOR[3].question}
                  onChange={formik.handleChange}
                >
                  {FORM_CONTENT_MENTOR[3].answers.map((answer) => (
                    <SelectOptionFix
                      value={answer.value}
                      key={`${FORM_CONTENT_MENTOR[3].name}-${answer.value}`}
                      selected={formik.values.seniority_level === answer.value}
                    >
                      {answer.label}
                    </SelectOptionFix>
                  ))}
                </Select>
              </Fieldset>
            </Box>
          </FormRow>
          <FormRow>
            <Box>
              <Fieldset
                info={FORM_CONTENT_MENTOR[4].info}
                itemSpacing="half"
                infoError={
                  formik.touched.work_exp_at_flix &&
                  formik.errors.work_exp_at_flix
                }
                valid={
                  formik.touched.work_exp_at_flix &&
                  formik.errors.work_exp_at_flix
                    ? false
                    : true
                }
              >
                <Select
                  id={`${FORM_CONTENT_MENTOR[4].name}`}
                  label={FORM_CONTENT_MENTOR[4].question}
                  onChange={formik.handleChange}
                >
                  {FORM_CONTENT_MENTOR[4].answers.map((answer) => (
                    <SelectOptionFix
                      value={answer.value}
                      key={`${FORM_CONTENT_MENTOR[4].name}-${answer.value}`}
                      selected={formik.values.work_exp_at_flix === answer.value}
                    >
                      {answer.label}
                    </SelectOptionFix>
                  ))}
                </Select>
              </Fieldset>
            </Box>
          </FormRow>
          <FormRow>
            <Button appearance="primary" type="submit">
              Submit
            </Button>
          </FormRow>
        </form>
      </PageContainer>
    </MainWrapper>
  );
};

export default MentorForm;
