import {
  Box,
  Button,
  Dropdown,
  Grid,
  GridCol,
  Heading,
  Popup,
  PopupSection,
  Text,
} from "@flixbus/honeycomb-react";
import {
  Icon,
  IconArrowDownL,
  IconArrowUpL,
  IconAttention,
} from "@flixbus/honeycomb-icons-react";

import React from "react";
import styles from "./MatchCard.module.scss";

type MatchCardProp = {
  name: string;
  jobTitle: string;
  officeLocation: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
};

const MatchCard: React.FC<MatchCardProp> = ({
  name,
  jobTitle,
  officeLocation,
  status,
  createdAt,
  updatedAt,
  updatedBy,
}) => {
  const [popupActive, setPopupActive] = React.useState(false);

  const [newStatus, setNewStatus] = React.useState("");

  let linkArray: any[] = [];
  let statusButtonAppearence: "primary" | "secondary" | "tertiary" = "tertiary";
  switch (status) {
    case "Waiting to meet":
      statusButtonAppearence = "primary";
      linkArray = [
        {
          text: "Start mentorship",
          href: "#",
          onClick: () => {
            setPopupActive(true);
            setNewStatus("Ongoing");
          },
        },
        {
          text: "Close mentorship",
          href: "#",
          onClick: () => {
            setPopupActive(true);
            setNewStatus("Closed");
          },
        },
      ];
      break;
    case "Ongoing":
      statusButtonAppearence = "secondary";
      linkArray = [
        {
          text: "Close mentorship",
          href: "#",
          onClick: () => {
            setPopupActive(true);
            setNewStatus("Closed");
          },
        },
      ];
      break;
    case "Closed":
      statusButtonAppearence = "tertiary";
      linkArray = [
        {
          text: "Resume mertorship",
          href: "#",
          onClick: () => {
            setPopupActive(true);
            setNewStatus("Waiting to meet");
          },
        },
      ];
      break;
    default:
      linkArray = [];
  }

  const [arrowUp, setArrowUp] = React.useState(false);

  return (
    <React.Fragment>
      <Box>
        <Grid>
          <GridCol size={12}>
            <Grid>
              <GridCol size={10} extraClasses={styles.matchCardTitleContainer}>
                <Heading size={3} flushSpace>
                  {name}
                </Heading>
                <Text>
                  {jobTitle} • {officeLocation}
                </Text>
              </GridCol>
              <GridCol size={2}>
                <Dropdown
                  links={linkArray}
                  onToggle={(isActive) => setArrowUp(isActive)}
                  smartPosition
                >
                  <Button appearance={statusButtonAppearence}>
                    {status}{" "}
                    <Icon
                      InlineIcon={arrowUp ? IconArrowUpL : IconArrowDownL}
                    />
                  </Button>
                </Dropdown>
              </GridCol>
            </Grid>
          </GridCol>
          <GridCol>
            <Grid>
              <GridCol size={2}>
                <Text Elem="div" small>
                  <b className={styles.matchCardHeading}>Match date:</b>{" "}
                  {createdAt.toLocaleDateString()}
                </Text>
              </GridCol>
              <GridCol size={2}>
                <Text Elem="div" small>
                  <b className={styles.matchCardHeading}>Update date:</b>{" "}
                  {updatedAt.toLocaleDateString()}
                </Text>
              </GridCol>
              <GridCol>
                <Text Elem="div" small>
                  <b className={styles.matchCardHeading}>Updated by:</b>{" "}
                  {updatedBy}
                </Text>
              </GridCol>
            </Grid>
          </GridCol>
        </Grid>
      </Box>
      <StatusChangePopup
        name={name}
        newStatus={newStatus}
        popupActive={popupActive}
        setPopupActive={setPopupActive}
      />
    </React.Fragment>
  );
};

type StatusChangePopupProp = {
  name: string;
  newStatus: string;
  popupActive: boolean;
  setPopupActive: (active: boolean) => void;
};

const StatusChangePopup: React.FC<StatusChangePopupProp> = ({
  name,
  newStatus,
  popupActive,
  setPopupActive,
}) => {
  const targetRef = React.useRef(null);

  return (
    <Popup
      id="status-change-popup"
      aria-labelledby="accessible-popup-title"
      aria-describedby="accessible-popup-content"
      active={popupActive}
      onOverlayClick={() => setPopupActive(false)}
      targetRef={targetRef}
    >
      <PopupSection type="icon" extraClasses="my-class">
        <Icon
          InlineIcon={IconAttention}
          size={12}
        />
      </PopupSection>
      <PopupSection type="title">
        <Heading id="accessible-popup-title" size={2}>
          Mentorship status change
        </Heading>
      </PopupSection>
      <PopupSection type="content" id="accessible-popup-content">
        {`You are about to change the mentorship status with ${name} to '${newStatus}'`}
      </PopupSection>
      <PopupSection type="actions">
        <Button appearance="tertiary" onClick={() => setPopupActive(false)}>
          Cancel
        </Button>
        <Button
          appearance="secondary"
          onClick={() => {
            // TODO: API request do change status
            setPopupActive(false);
          }}
        >
          Confirm
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default MatchCard;
