export const msalConfig = {
  /**
   * This config is taken from the Azure portal for the app registration
   */
  auth: {
    clientId: process.env.REACT_APP_AZURE_ID as string,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: `${document.location.origin}/login`,
    postLogoutRedirectUri: `${process.env.REACT_APP_BASE_URL}`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
