import {
  FormRow,
  Grid,
  GridCol,
  Heading,
  Link,
  MainWrapper,
  PageContainer,
  Text,
} from "@flixbus/honeycomb-react";
import { useMatchaStore, useMatches } from "../../features/state-management/matcha-state";

import { APP_PATHS } from "../../constants";
import MatchCard from "../../features/match-card/MatchCard";
import { NavLink } from "react-router-dom";
import React from "react";
import styles from "./Matches.module.scss";

type MatchesProp = {
  type: "mentee" | "mentor";
};

const Matches: React.FC<MatchesProp> = ({ type }) => {
  const userProfile = useMatchaStore((state) => state.userProfile);
  const match_data = useMatches(userProfile?.id, type)

  const join_link =
    type === "mentee" ? APP_PATHS.MENTEE_FORM : APP_PATHS.MENTOR_FORM;

  return (
    <MainWrapper full extraClasses={styles.backgroudColor}>
      <PageContainer>
        <Grid>
          <GridCol size={12} extraClasses={styles.headingContainer}>
            <Heading size={1}>{`My ${
              type === "mentee" ? "mentor" : "mentee"
            }(s)`}</Heading>
          </GridCol>
          {match_data.length > 0 ? (
            <GridCol>
              {match_data.map((match, index) => (
                <FormRow key={`row-match-${index}`}>
                  <MatchCard
                    name={match.display_name}
                    jobTitle={match.job_title}
                    officeLocation={match.office_location}
                    status={match.status}
                    createdAt={new Date(Number(match.created_at) * 1000)}
                    updatedAt={new Date(Number(match.updated_at) * 1000)}
                    // TODO: include updated by on the match information
                    updatedBy={match.display_name} 
                  />
                </FormRow>
              ))}
            </GridCol>
          ) : (
            <GridCol>
              <Text>
                Looks like you have not matches yet, if you already have{" "}
                <Link RouterLink={NavLink} href={join_link}>
                  joined as a {type}
                </Link>{" "}
                you will receive an email when we find a good match for you.
              </Text>
            </GridCol>
          )}
        </Grid>
      </PageContainer>
    </MainWrapper>
  );
};

export default Matches;
