// Add fonts used by Honeycomb
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

import { Login, UserProfile } from "./pages/authentication/Login";
import { Navigate, BrowserRouter as Router, useRoutes } from "react-router-dom";
import React, { useMemo } from "react";
import { ThemeWrapper, themeDefault } from "@flixbus/honeycomb-react";

import { APP_PATHS } from "./constants";
import Header from "./features/header/Header";
import Home from "./pages/home/Home";
import { Logout } from "./pages/authentication/Logout";
import Matches from "./pages/matches/Matches";
import MenteeForm from "./pages/form/mentee/MenteeForm";
import MentorForm from "./pages/form/mentor/MentorForm";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./pages/authentication/auth-config";
import { useMatchaStore } from "./features/state-management/matcha-state";

const msalInstance = new PublicClientApplication(msalConfig);

const AppRoutes = () => {
  const { setUserProfile, userProfile } = useMatchaStore();

  const routes = useMemo(() => {
    interface ProtectedRouteProps {
      userProfile: UserProfile | null;
      children: JSX.Element;
    }

    function ProtectedRoute({
      userProfile,
      children,
    }: ProtectedRouteProps): JSX.Element {
      if (!userProfile) {
        return <Navigate to="/login" replace />;
      }

      return children;
    }

    return [
      {
        path: APP_PATHS.LOGIN,
        element: (
          <Login setUserProfile={setUserProfile} userProfile={userProfile} />
        ),
      },
      { path: APP_PATHS.LOGOUT, element: <Logout /> },
      { path: APP_PATHS.HOME, element: <Home /> },
      {
        path: APP_PATHS.MENTEE_FORM,
        element: (
          <ProtectedRoute userProfile={userProfile}>
            <MenteeForm />
          </ProtectedRoute>
        ),
      },
      {
        path: APP_PATHS.MENTOR_FORM,
        element: (
          <ProtectedRoute userProfile={userProfile}>
            <MentorForm />
          </ProtectedRoute>
        ),
      },
      {
        path: APP_PATHS.MENTEE_MATCHES,
        element: (
          <ProtectedRoute userProfile={userProfile}>
            <Matches type="mentee"/>
          </ProtectedRoute>
        ),
      },
      {
        path: APP_PATHS.MENTOR_MATCHES,
        element: (
          <ProtectedRoute userProfile={userProfile}>
            <Matches type="mentor"/>
          </ProtectedRoute>
        ),
      },
      // { path: '*', element: <NotFound /> },
    ];
  }, [setUserProfile, userProfile]);

  return useRoutes(routes);
};

function App() {
  return (
    <Router>
      <ThemeWrapper theme="default" themes={{ default: themeDefault }}>
        <MsalProvider instance={msalInstance}>
          <Header />
          <AppRoutes />
        </MsalProvider>
      </ThemeWrapper>
    </Router>
  );
}

export default App;
