export enum FormType {
  MENTEE = "5501e3c8-22ae-4efb-a619-aa25668797e2",
  MENTOR = "58f7659e-a81b-48c7-a364-a4be056e302e",
}

const answerOptions = {
  mentorship_approach: [
    {
      value: "ShareKnowledge",
      label: "share their knowledge and experience",
    },
    {
      value: "BuildNetwork",
      label: "help me build a network / share knowledge on Flix",
    },
    {
      value: "SelfReflection",
      label: "partner on self reflection",
    },
    {
      value: "CareerAdvice",
      label: "offer career advice",
    },
    {
      value: "SparringPartner",
      label: "be a sparring partner on topics from the daily work",
    },
  ],
  functional_expertise: [
    {
      value: "marketing",
      label: "Marketing",
    },
    {
      value: "finance",
      label: "Finance",
    },
    {
      value: "legal",
      label: "Legal",
    },
    {
      value: "hr",
      label: "HR",
    },
    {
      value: "engineering",
      label: "Engineering",
    },
    {
      value: "product",
      label: "Product development",
    },
    {
      value: "leadership",
      label: "Leadership",
    },
    {
      value: "operations",
      label: "Operations",
    },
    {
      value: "sales",
      label: "Business development / Sales",
    },
  ],
  skill_expertise: [
    {
      value: "project_management",
      label: "Project management",
    },
    {
      value: "presentation_skills",
      label: "Presentation skills",
    },
    {
      value: "stakeholder_management",
      label: "Stakeholder management",
    },
    {
      value: "communication_skills",
      label: "Communication skills",
    },
    {
      value: "meeting_facilitation",
      label: "Meeting facilitation",
    },
  ],
  seniority_level: [
    {
      value: "0",
      label: "Select an option",
    },
    {
      value: "1",
      label: "Junior",
    },
    {
      value: "2",
      label: "Mid",
    },
    {
      value: "3",
      label: "Senior",
    },
  ],
  work_exp_at_flix: Array.from({ length: 101 }).map((_, index) => {
    return { value: `${index}`, label: `${index}` };
  }),
};

export const FORM_CONTENT_MENTEE: Array<{
  fieldId: string;
  name: string;
  info: string;
  question: string;
  answers: Array<{ value: string; label: string }>;
}> = [
  {
    fieldId: "eb88440a-0792-4c61-98cc-be4141cccc58",
    name: "mentorship_approach",
    info: "Please select at most 3 options.",
    question: "I mostly look for a mentor to: (*)",
    answers: answerOptions.mentorship_approach,
  },
  {
    fieldId: "af2d4048-e7b6-457c-b46f-0936734a21e5",
    name: "functional_expertise",
    info: "Please select at most 3 options.",
    question: "I am looking for expertise in these functions: (*)",
    answers: answerOptions.functional_expertise,
  },
  {
    fieldId: "1e7f5914-a5f9-4713-98e1-15c008869262",
    name: "skill_expertise",
    question: "I am looking for expertise in these skills: (*)",
    info: "",
    answers: answerOptions.skill_expertise,
  },
  {
    fieldId: "9890cc5b-1c1a-495b-ab7a-2f377c57a41b",
    name: "seniority_level",
    question: "The seniority level of my current role is: (*)",
    info: "",
    answers: answerOptions.seniority_level,
  },
  {
    fieldId: "b5cca5bd-7d90-44a4-a2cf-a88a8e2faf92",
    name: "work_exp_at_flix",
    question: "I work at Flix since _ years: (*)",
    info: "",
    answers: answerOptions.work_exp_at_flix,
  },
];

export const FORM_CONTENT_MENTOR: Array<{
  fieldId: string;
  name: string;
  info: string;
  question: string;
  answers: Array<{ value: string; label: string }>;
}> = [
  {
    fieldId: "f1cea3f0-de6e-45c2-92fb-42bb18c4326e",
    name: "mentorship_approach",
    info: "Please select at most 3 options.",
    question: "I want to offer mentorship in these areas: (*)",
    answers: answerOptions.mentorship_approach,
  },
  {
    fieldId: "7854f274-c981-4701-be3b-0eab72d0aeed",
    name: "functional_expertise",
    info: "Please select at most 3 options.",
    question: "I am an expert in these functions: (*)",
    answers: answerOptions.functional_expertise,
  },
  {
    fieldId: "df7fcf72-2773-4e0f-8e62-fd70408c482c",
    name: "skill_expertise",
    question: "I can offer expertise for these specific skills:",
    info: "(optional)",
    answers: answerOptions.skill_expertise,
  },
  {
    fieldId: "98211ef0-1040-495e-9c63-35a0c36e390b",
    name: "seniority_level",
    question: "The seniority level of my current role is: (*)",
    info: "",
    answers: answerOptions.seniority_level,
  },
  {
    fieldId: "6420c742-5e89-43e0-b9bd-6fea633282b8",
    name: "work_exp_at_flix",
    question: "I work at Flix since _ years: (*)",
    info: "",
    answers: answerOptions.work_exp_at_flix,
  },
];
