import React from "react";
import { useMsal } from "@azure/msal-react";

export function Logout() {
  const { instance } = useMsal();

  React.useEffect(() => {
    async function logout() {
      try {
        await instance.initialize();
        await instance.logoutRedirect({
          postLogoutRedirectUri: "/login",
        });
      } catch (error) {
        console.error(error);
      }
    }
    logout();
  }, [instance]);

  return <span>Signing out...</span>;
}
